'use strict';
import { useState, useEffect } from 'react';
import EMS_REDUCER_CASE, { EMS_MODE } from './Store';
import styles from './style/editor.editContent.module.scss';
import commonstyles from './style/editor.common.module.scss';

// let targetJSX = null;

const editTargetData_default = {
  no: null,
  tag: null,
  children: null,
  file_place: null,
  style: {
    fontSize: '',
    fontWeight: '',
    color: ''
  }
}

/**
 * Function Component EditContent
 * データの仕様：
 * <EMS>
 * EMS.target: 編集前に、編集するターゲットの情報を格納する。バックアップデータの役割を果たす。
 * EMS.jsx[editTargetData.no]: 編集中のターゲットの本データ。コミット関数によって、編集内容がコミットされる。
 * 
 * <EditContent>
 * editTargetData: 編集内容をEditModeの間保存し、適宜commit関数への引数とする。begin時にセット、end時にリセット。
 * 
 * 
 * @param {object} props 
 * @returns {void} 
 */
const EditContent = props => {
  /**
   * fetch: 編集対象のEMSデータの取り出し
   * commit: 編集内容をEMSに反映
   * commitAndEndEditMode: commit()実行後にendEditMode()も実行する
   * rollback: 編集内容を破棄し、EMS.targetに保管されている編集前の状態に戻す
   */
  const fetch = (no) => { return props.ems.jsx[no]; }
  const commit = (t) => props.onUpdateEMS.commit(t);
  const rollback = () => {
    console.log("rollback", props.ems.target);
    commit(props.ems.target);
    endEditMode();
  }

  /**
   * useState: editTargetData
   */
  const [editTargetData, setEditTargetData] = useState(() => {
    if (props.target.getAttribute("no") && props.target.getAttribute("editable")) {

      console.log("editTargetData initial set");

      const fetch_result = fetch(
        Number(props.target.getAttribute("no"))
      );


      return {
        no: Number(fetch_result.props.no),
        tag: fetch_result.tag,
        children: fetch_result.children,
        style: (fetch_result.props.style === undefined) ?
          editTargetData_default.style :
          fetch_result.props.style
      }
    }
  })

  /**
   * dependencyがempty arrayなので、
   * componentDidMount(),
   * componentWillUnmount()
   * を表す
   * 
   * DOMにEditメニューがレンダリングされた後は、編集を保存・破棄する以外に
   * Editモードを終了することはない
   * ->マウント時に一回だけEdit Mode設定、
   * 　アンマウント時にDefault Modeに戻せばよい
   */
  useEffect(() => {
    /**
     * executed when mounted
     */
    if (props.target.getAttribute("editable") === "1") {
      console.log("mode edit")
      props.onUpdateEMS.toEditMode(
        fetch(
          Number(props.target.getAttribute("no"))
        )
      );
    }

    /**
     * executed when unmount
     */
    return props.onUpdateEMS.toDefaultMode();
  }, []);

  /**
   * Style Form: inputへの入力値のstateへの反映
   * for controlled component
   * @param {object} e 
   */
  const handleOnStyleChange = (e) => {
    const styleData_input = { ...editTargetData.style };
    styleData_input[e.target.getAttribute("name")] = e.target.value;

    setEditTargetData({
      ...editTargetData,
      style: styleData_input
    })
  }
  const handleOnInfoChange = (e) => {
    const data_input = { ...editTargetData };
    data_input[e.target.getAttribute("name")] = e.target.value;

    setEditTargetData({
      ...data_input
    })
  }
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const fetch_result = fetch(editTargetData.no);
    const commit_data = {
      tag: editTargetData.tag,
      children: editTargetData.children,
      props: {
        ...fetch_result.props,
        style: editTargetData.style
      }
    }
    commit(commit_data);
  }

  /**
   * endEditMode
   * EditModeを終了する関数
   * EditMode終了時はこれを呼び出すだけでOK
   */
  const endEditMode = () => {
    props.onUpdateEMS.toDefaultMode();
    props.close();
  }

  /**
   * Edit Menu render component
   */
  return (
    <div>
      <ul className={`${styles['edit-form']} ${commonstyles['edit-menu-wrapper']}`}>
        <li>
          <p>コンテンツの編集</p>

          <form id="edit_form" onSubmit={handleOnSubmit} >

            <div>
              <p className={styles['section-title']}>編集中ターゲットの要素</p>

              <label htmlFor="tag">要素の種類</label>
              <input type="text" name="tag" value={editTargetData.tag} onChange={handleOnInfoChange} />

              <label htmlFor="children">要素の中身</label>
              <textarea type="text" name="children" value={editTargetData.children} onChange={handleOnInfoChange} />
            </div>

            <div>
              <p className={styles['section-title']}>編集中ターゲットのスタイル</p>

              <label htmlFor="fontSize">文字サイズ</label>
              <input type="text" name="fontSize" value={editTargetData.style['fontSize']} onChange={handleOnStyleChange} />

              <label htmlFor="fontWeight">文字の太さ</label>
              <input type="text" name="fontWeight" value={editTargetData.style['fontWeight']} onChange={handleOnStyleChange} />

              <label htmlFor="color">文字色</label>
              <input type="text" name="color" value={editTargetData.style['color']} onChange={handleOnStyleChange} />

              {/* <label htmlFor="manual-style">追加スタイルを手動で入力</label>
              <textarea name="manual-style" type="text"></textarea> */}
            </div>
          </form>
          <button form="edit_form" className={styles['event-btn']} type="submit">変更内容を反映する</button>

        </li>
        <li className={styles['event-btn']} onClick={endEditMode}>保存して編集モードを終了</li>
        <li className={styles['event-btn']} onClick={rollback}>保存せずに編集モードを終了</li>
        <li>
        </li>
      </ul>
    </div>
  )
}

export default EditContent;