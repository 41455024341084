'use strict';
import React from 'react';
import styles from './style/editor.addContent.module.scss';
import commonstyles from './style/editor.common.module.scss';
import InlineMenu from '../UI/parts/InlineMenu';

const AddContent = (props) => {

  /**
   * generating JSX infomation and JSX element
   * @param {string} id 
   */
  const generateJsxInfo = (id) => {
    const propsToJSXModule =
      (id === "img" || id === "movie") ?
        {
          tag: id,
          type: "media",
          props: {
            key: props.ems.jsx.length.toString(),
            no: props.ems.jsx.length.toString(),
            editable: "1",
          }
        }
        :
        {
          tag: id,
          type: "text",
          children: 'ここにテキストが入ります',
          props: {
            key: props.ems.jsx.length.toString(),
            no: props.ems.jsx.length.toString(),
            editable: "1"
          }
        }

    // EMS_add(propsToJSXModule);
    props.onUpdateEMS.add(propsToJSXModule);
  }

  window.onclick = (e) => {

    const validTagList = ['h1', 'h2', 'h3', 'h4', 'p', 'img', 'video']
    if (e.target.id === "closeAddContentMenu") {
      props.close();
    }
    else if (validTagList.includes(e.target.id)) {
      generateJsxInfo(e.target.id);
      props.close();
    }

    // switch (e.target.id) {
    //   case "":
    //     break;

    //   // close btn on add content menu
    //   case "closeAddContentMenu":
    //     props.close();
    //     break;

    //   // generate JSX tag if e.target has valid id
    //   default:
    //     generateJsxInfo(e.target.id);
    //     props.close();
    //     break;
    // }


  }

  return (
    <div id="add_content_menu">
      <InlineMenu className={`${styles['add-menu']} ${commonstyles['edit-menu-wrapper']}`}>
        <li>
          <h3>見出し・タイトル</h3>
          <ul className={`${styles['flex-row']}`}>
            <li id="h1">h1</li>
            <li id="h2">h2</li>
            <li id="h3">h3</li>
            <li id="h4">h4</li>
          </ul>
        </li>
        <li id="p">本文</li>
        <li id="media">
          <h3>メディア</h3>
          <ul className={styles['flex-row']}>
            <li id="img">画像</li>
            <li id="video">動画</li>
          </ul>
        </li>
        <li id="closeAddContentMenu">メニューを閉じる</li>
      </InlineMenu>
    </div>
  );
}


export default AddContent;