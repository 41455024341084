'use strict';
/**
 * EMS mode
 */
const EMS_MODE = {
  EDIT: 'EDIT',
  DEFAULT: 'DEFAULT'
}
/**
 * manage Edit State.
 * 
 * mode: edit, default
 * jsx: JSX data
 */
const EditModelState = {
  mode: EMS_MODE.DEFAULT,
  target: {}, //backup for rollback
  jsx: []
}
/**
 * EMS reducer case pattern
 */
const EMS_REDUCER_CASE = {
  TO_EDIT: "MODE: Edit",
  TO_DEFAULT: "MODE: Default",
  COMMIT: "Commit",
  ADD: "Add",
}

/**
 * EMS reducer function
 * 
 * @param {object} state
 * @param {object} action 
 * @returns {object}
 */
const EMSReducer = (state, action) => {
  switch (action.type) {

    case EMS_REDUCER_CASE.ADD:
      console.log("EMS_REDUCER: ADD");
      return state = {
        mode: state.mode,
        target: state.target,
        jsx: [
          ...state.jsx,
          action.target
        ]
      }

    /**
     * 変更を保存
     */
    case EMS_REDUCER_CASE.COMMIT:
      console.log("EMS_REDUCER: TO_COMMIT");
      return state = {
        mode: state.mode,
        target: state.target,
        /**
         * indexが変更対象のものであるときのみ、
         * 渡されたターゲットのデータをJSXに入れる(更新作業)
         * それ以外の場合は以前のデータを入れる
         */
        jsx: state.jsx.map(
          (value, index) => {
            console.log(action.target.props);
            return (index === Number(action.target.props.no)) ? action.target : value
          }
        )
      }

    // case EMS_REDUCER_CASE.SET_TARGET:
    //   return state = {
    //     mode: state.mode,
    //     target: action.target,
    //     jsx: state.target
    //   }

    /**
     * モードチェンジ
     */
    case EMS_REDUCER_CASE.TO_EDIT:
      console.log("EMS_REDUCER: TO_EDIT", action.target.props);
      return state = {
        mode: EMS_MODE.EDIT,
        target: action.target,
        jsx: state.jsx.map((value, index) => {
          if (!index === Number(action.target.props.no))
            value.props.editable = "0"
          return value;
        })
      }

    case EMS_REDUCER_CASE.TO_DEFAULT:
      console.log("EMS_REDUCER: TO_DEFAULT");
      return state = {
        mode: EMS_MODE.DEFAULT,
        target: state.target,
        jsx: state.jsx.map((value) => {
          value.props.editable = "1"
          return value;
        })
      }

    default:
      return state;
  }
}
// export default connect(updateEMS, )
export { EditModelState, EMSReducer, EMS_REDUCER_CASE, EMS_MODE };
