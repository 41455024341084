/**
 * App.js
 * This Components controlls UI, Variables which are used in each Component.
 */

import React from 'react';
import classes from './App.scss';

import Header from './Components/UI/Header';
import Footer from './Components/UI/Footer';
import Editor from './Components/Editor/Editor';

function App() {
  return (
    <React.Fragment>
      <Header />
      <Editor />
      <Footer />
    </React.Fragment>
  );
}

export default App;
