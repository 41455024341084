import React from 'react';
import _CONFIG from '../../config';
import classes from './header-footer.scss';


const Header = () => {
  return <header>
    <h1>Blog Editor</h1>
    <p>|| version: {_CONFIG.VERSION}</p>
    <p>author: {_CONFIG.AUTHOR} ||</p>
  </header>
}

export default Header;