'use strict';
import React, { useReducer } from 'react';
import { useState } from 'react';
import { EditModelState, EMSReducer, EMS_REDUCER_CASE, EMS_MODE } from './Store';

// import { DragDropContext, Droppable, useDrag } from 'react-beautiful-dnd';

/**
 * functional components import
 */
import AddContent from './AddContent';
import EditContent from './EditContent';

/**
 * styles import
 */
import layoutStyles from './style/editor.mainlayout.module.scss';
import addContentBtnstyles from './style/editor.addContent.module.scss';

/**
 * Consts for call other components
 */

const CALL = {
  ADD_JSX: "addContent",
  EDIT_JSX: "editContent"
}

/**
 * consts for error type
 */
const ERROR = {
  CANNOT_OPEN_ADDMENU: '現在、コンテンツを追加することができません。編集中の場合は、"保存して終了"を選択したうえでもう一度操作してください。',
  CANNOT_BEGIN_EDITMODE: '編集モードの起動に失敗しました。',
  CANNOT_CHANGE_EDITTARGET: '既存の編集内容の保存・破棄を行ってから、もう一度編集する対象を選択して下さい。',
  UNEXPECTED_CASE_IN_DISPLAY_ERROR_HANDLER: 'アプリケーションエラーが発生しました。(UNEXPECTED_CASE_IN_DISPLAY_ERROR_HANDLER)'
}

/**
 * Editor Main Component
 * @returns 
 */
const Editor = () => {
  const [EMS, dispatch] = useReducer(EMSReducer, EditModelState);

  const [editTarget, setEditTarget] = useState(null);

  const [displayMenuFlg, setDisplayMenuFlg] = useState({
    addContentMenu: false,
    editContentMenu: false
  });

  /**
 * dispatches handler
 */
  const updateEMS = {
    add: t => dispatch({ type: EMS_REDUCER_CASE.ADD, target: t }),//t: 追加要素のJSXデータ
    commit: t => dispatch({ type: EMS_REDUCER_CASE.COMMIT, target: t }),//t: EditMode等で変更した対象のデータ
    toEditMode: t => dispatch({ type: EMS_REDUCER_CASE.TO_EDIT, target: t }),//t: EditModeの対象(編集前のjsxデータ)。rollback時に使用する、バックアップデータとして活用
    toDefaultMode: () => dispatch({ type: EMS_REDUCER_CASE.TO_DEFAULT })
  }

  const displayMenuHandler = (mode, target) => {
    console.log("EMS mode now: ", EMS.mode);
    switch (mode) {
      case CALL.ADD_JSX:
        if (EMS.mode === EMS_MODE.DEFAULT) {
          setDisplayMenuFlg({
            addContentMenu: true,
            editContentMenu: false
          });
        }
        else displayErrorHandler(ERROR.CANNOT_OPEN_ADDMENU);
        break;

      case CALL.EDIT_JSX:
        if (!displayMenuFlg.editContentMenu) {
          setEditTarget(target);

          setDisplayMenuFlg({
            addContentMenu: false,
            editContentMenu: true
          });

        } else if (!target.getAttribute("editable") === "1") {
          displayErrorHandler(ERROR.CANNOT_CHANGE_EDITTARGET);
        }
        break;

      default:
        displayErrorHandler(ERROR.UNEXPECTED_CASE_IN_DISPLAY_ERROR_HANDLER);
    }

  }

  const displayErrorHandler = (err) => {
    console.log("エラー：", err);
  }

  const closeMenu = () => {
    setEditTarget(null);
    setDisplayMenuFlg({
      addContentMenu: false,
      editContentMenu: false
    })
  }

  /**
   * addEventListener for click/touch
   * Handle Btn, Menu, and other UI components display status
   * Controlls all user manipulations here.
   * 
   * @param {object} e 
   */
  window.onclick = (e) => {

    if (e.target.id === "addContentBtn") {
      displayMenuHandler(CALL.ADD_JSX, e.target);
    }
    else if (e.target.getAttribute("editable") === "1") {
      displayMenuHandler(CALL.EDIT_JSX, e.target);
    }
  }



  return (
    <section id="editor" className={layoutStyles['editor-wrapper']}>
      <div id="editor_content_display" className={layoutStyles['editor-content-display']}>
        {
          /** 
           * EMSに保存されているコンテンツを表示
           */
          EMS.jsx.map((t) => {
            return React.createElement(t.tag, t.props, t.children);
          })
        }
        <button id="addContentBtn" className={addContentBtnstyles.addContentBtn}>+</button>
        {
          /**
           * addcontentmenuを展開
           */
          displayMenuFlg.addContentMenu &&
          <AddContent
            close={closeMenu}
            ems={EMS}
            onUpdateEMS={updateEMS} />
        }
      </div>
      {
        /**
         * editcontentmenuを展開
         */
        displayMenuFlg.editContentMenu &&
        <EditContent
          eventFlg={displayMenuFlg.editContentMenu}
          target={editTarget}
          ems={EMS}
          errorType={ERROR}
          close={closeMenu}
          onHandleError={displayErrorHandler}
          onUpdateEMS={updateEMS} />
      }
    </section>
  )
}

export default Editor;